@charset "utf-8";

/*横幅增加特效*/
.swipe {
    /* perspective设置必须是transform-orign所在值的父标签，为的是修复IOS系统下Safari的图片被放大的bug	*/
    /*-webkit-perspective: 1600px; perspective: 1600px;注释掉这句是由于横幅图片在iphone safari浏览器下模糊的问题*/
    -moz-perspective: 1600px;
    -o-perspective: 1600px;
    -ms-perspective: 1600px;

    /*GPU加速*/
    transform: rotateX(0deg) rotateY(0deg) translate3d(0, 0, 0);
}

.billboard {
    overflow: hidden;
    position: relative;

    /* 加transform-origin,可以解决IOS系统下Safari和Chrome图片因为设置了perspective而被放大的bug，是因为Z轴聚焦点错了导致 */
    transform-origin: center center;
}

.billboard4 {
    /* 这一层级加多perspective，是为了fix webkit内核浏览器，在水平翻转的时候图片有裂痕 */
    perspective: 1600px;
}

/* 淡入淡出，水平滑动，垂直滑动，聚焦效果不需要3D */
.billboard1,
.billboard2,
.billboard3,
.billboardItem_11,
.billboardItem_13 {
    perspective: none;

    transform-style: flat;
}

.billboard_item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.billboardAnim {
    animation-fill-mode: both;

    animation-timing-function: ease-in-out;
}

/* 1 水平滑动 */
.billboardItem_1 {
    transform: translate3d(100%, 0, 0);
}

.billboardItem_1_start {
    transform: translate3d(0, 0, 0);
}

.billboardItem_1_on {
    animation-name: billboardItem_1_on;
}

.billboardItem_1_on_reverse {
    animation-name: billboardItem_1_on_reverse;
}

.billboardItem_1_off {
    animation-name: billboardItem_1_off;
}

.billboardItem_1_off_reverse {
    animation-name: billboardItem_1_off_reverse;
}

@keyframes billboardItem_1_on {
    0% {
        transform: translate3d(100%, 0, 0);
    }

    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes billboardItem_1_on_reverse {
    0% {
        transform: translate3d(-100%, 0, 0);
    }

    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes billboardItem_1_off {
    0% {
        transform: translate3d(0, 0, 0);
    }

    100% {
        transform: translate3d(-100%, 0, 0);
    }
}

@keyframes billboardItem_1_off_reverse {
    0% {
        transform: translate3d(0, 0, 0);
    }

    100% {
        transform: translate3d(100%, 0, 0);
    }
}

/* 2 垂直滑动 */
.billboardItem_2 {
    transform: translate3d(0, 100%, 0);
}

.billboardItem_2_start {
    transform: translate3d(0, 0, 0);
}

.billboardItem_2_on {
    animation-name: billboardItem_2_on;
}

.billboardItem_2_on_reverse {
    animation-name: billboardItem_2_on_reverse;
}

.billboardItem_2_off {
    animation-name: billboardItem_2_off;
}

.billboardItem_2_off_reverse {
    animation-name: billboardItem_2_off_reverse;
}

@keyframes billboardItem_2_on {
    0% {
        transform: translate3d(0, -100%, 0);
    }

    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes billboardItem_2_on_reverse {
    0% {
        transform: translate3d(0, 100%, 0);
    }

    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes billboardItem_2_off {
    0% {
        transform: translate3d(0, 0, 0);
    }

    100% {
        transform: translate3d(0, 100%, 0);
    }
}

@keyframes billboardItem_2_off_reverse {
    0% {
        transform: translate3d(0, 0, 0);
    }

    100% {
        transform: translate3d(0, -100%, 0);
    }
}

/* 4 水平3D翻转 */
.billboard3 {
    -ms-perspective: none;
    /* IE 系列有bug，暂时退化*/
}

.billboard3 * {
    transform-style: preserve-3d;
}

.billboardItem_3 {
    transform: rotateY(-90deg);
}

.billboardItem_3 * {
    backface-visibility: hidden;
}

.billboardItem_3_start {
    transform: rotateY(0deg);
}

.billboardItem_3_on {
    animation-name: billboardItem_3_on;
}

.billboardItem_3_on_reverse {
    animation-name: billboardItem_3_on_reverse;
}

.billboardItem_3_off {
    animation-name: billboardItem_3_off;
}

.billboardItem_3_off_reverse {
    animation-name: billboardItem_3_off_reverse;
}

@keyframes billboardItem_3_on {
    0% {
        transform: rotateY(-90deg);
    }

    100% {
        transform: rotateY(0deg);
    }
}

@keyframes billboardItem_3_on_reverse {
    0% {
        transform: rotateY(90deg);
    }

    100% {
        transform: rotateY(0deg);
    }
}

@keyframes billboardItem_3_off {
    0% {
        transform: rotateY(0deg);
    }

    100% {
        transform: rotateY(90deg);
    }
}

@keyframes billboardItem_3_off_reverse {
    0% {
        transform: rotateY(0deg);
    }

    100% {
        transform: rotateY(-90deg);
    }
}

/* 3 垂直3D翻转 */
.billboard4 {
    -ms-perspective: none;
    /* IE 系列有bug，暂时退化*/
}

.billboard4 * {
    transform-style: preserve-3d;
}

.billboardItem_4 {
    transform: rotateY(90deg);
}

.billboardItem_4 * {
    backface-visibility: hidden;
}

.billboardItem_4_start {
    transform: rotateY(0deg);
}

.billboardItem_4_on {
    animation-name: billboardItem_4_on;
}

.billboardItem_4_on_reverse {
    animation-name: billboardItem_4_on_reverse;
}

.billboardItem_4_off {
    animation-name: billboardItem_4_off;
}

.billboardItem_4_off_reverse {
    animation-name: billboardItem_4_off_reverse;
}

@keyframes billboardItem_4_on {
    0% {
        transform: rotateX(90deg);
    }

    100% {
        transform: rotateX(0deg);
    }
}

@keyframes billboardItem_4_on_reverse {
    0% {
        transform: rotateX(-90deg);
    }

    100% {
        transform: rotateX(0deg);
    }
}

@keyframes billboardItem_4_off {
    0% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(-90deg);
    }
}

@keyframes billboardItem_4_off_reverse {
    0% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(90deg);
    }
}

/* 4 水平翻转 */
.billboard5 * {
    transform-style: preserve-3d;
}

.billboardItem_5 {
    transform: rotateY(90deg);
}

.billboardItem_5 * {
    backface-visibility: hidden;
}

.billboardItem_5_start {
    transform: rotateY(0deg);
}

.billboardItem_5_on {
    animation-name: billboardItem_5_on;
}

.billboardItem_5_on_reverse {
    animation-name: billboardItem_5_on_reverse;
}

.billboardItem_5_off {
    animation-name: billboardItem_5_off;
}

.billboardItem_5_off_reverse {
    animation-name: billboardItem_5_off_reverse;
}

@keyframes billboardItem_5_on {
    0% {
        transform: rotateY(-90deg);
    }

    50% {
        transform: rotateY(-90deg);
    }

    100% {
        transform: rotateY(0deg);
    }
}

@keyframes billboardItem_5_on_reverse {
    0% {
        transform: rotateY(90deg);
    }

    50% {
        transform: rotateY(90deg);
    }

    100% {
        transform: rotateY(0deg);
    }
}

@keyframes billboardItem_5_off {
    0% {
        transform: rotateY(0deg);
    }

    50% {
        transform: rotateY(90deg);
    }

    100% {
        transform: rotateY(90deg);
    }
}

@keyframes billboardItem_5_off_reverse {
    0% {
        transform: rotateY(0deg);
    }

    50% {
        transform: rotateY(-90deg);
    }

    100% {
        transform: rotateY(-90deg);
    }
}

/* 5 垂直翻转 */
.billboard6 * {
    transform-style: preserve-3d;
}

.billboardItem_6 {
    transform: rotateX(90deg);
}

.billboardItem_6 * {
    backface-visibility: hidden;
}

.billboardItem_6_start {
    transform: rotateX(0deg);
}

.billboardItem_6_on {
    animation-name: billboardItem_6_on;
}

.billboardItem_6_on_reverse {
    animation-name: billboardItem_6_on_reverse;
}

.billboardItem_6_off {
    animation-name: billboardItem_6_off;
}

.billboardItem_6_off_reverse {
    animation-name: billboardItem_6_off_reverse;
}

@keyframes billboardItem_6_on {
    0% {
        transform: rotateX(-90deg);
    }

    50% {
        transform: rotateX(-90deg);
    }

    100% {
        transform: rotateY(0deg);
    }
}

@keyframes billboardItem_6_on_reverse {
    0% {
        transform: rotateX(90deg);
    }

    50% {
        transform: rotateX(90deg);
    }

    100% {
        transform: rotateY(0deg);
    }
}

@keyframes billboardItem_6_off {
    0% {
        transform: rotateX(0deg);
    }

    50% {
        transform: rotateX(90deg);
    }

    100% {
        transform: rotateX(90deg);
    }
}

@keyframes billboardItem_6_off_reverse {
    0% {
        transform: rotateX(0deg);
    }

    50% {
        transform: rotateX(-90deg);
    }

    100% {
        transform: rotateX(-90deg);
    }
}

/* 7 淡入 */
.billboardItem_7 {
    opacity: 0;
}

.billboardItem_7_start {
    opacity: 1;
}

.billboardItem_7_on {
    animation-name: billboardItem_7_on;
}

.billboardItem_7_off {
    animation-name: billboardItem_7_off;
}

@keyframes billboardItem_7_on {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes billboardItem_7_off {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/* 8 淡入放大 */
.billboardItem_8 {
    opacity: 0;
    /*scale(1.4);*/
}

.billboardItem_8_start {
    opacity: 1;
    /*scale(1);*/
}

.billboardItem_8_on {
    animation-name: billboardItem_8_on;
}

.billboardItem_8_off {
    animation-name: billboardItem_8_off;
}

@keyframes billboardItem_8_on {
    0% {
        opacity: 0;
        transform: scale(1.4);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes billboardItem_8_off {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(1.4);
    }
}

/* 9 水平魔方旋转*/
.billboard9 {
    -webkit-perspective: none;
    /*safari 内核会让背景计算错误，拉伸了*/
}

.billboard9 * {
    transform-style: preserve-3d;
}

.billboardItem_9 {
    width: 100%;
    height: 100%;
}

.billboardItem_9 * {
    backface-visibility: hidden;
}

.billboardItem_9 .billboardImgInner {
    width: 100% !important;
}

.billboardItem_9 .billboardTile {
    position: absolute;
    width: 100%;
    height: 33.33%;
    left: 0;
    overflow: hidden;
}

.billboardItem_9_start .billboardTile,
.billboardItem_9_off .billboardTile,
.billboardItem_9_on .billboardTile {
    visibility: visible;
}

.billboardItem_9 .billboardTile_1 {
    top: 0;
}

.billboardItem_9 .billboardTile_2 {
    top: 33.33%;
}

.billboardItem_9 .billboardTile_3 {
    top: 66.55%;
}

.billboardItem_9 .billboardImg {
    position: absolute;
    left: 0;
    width: 100%;
    height: 300%;
}

.billboardItem_9 .billboardTile_1 .billboardImg {
    top: 0;
}

.billboardItem_9 .billboardTile_2 .billboardImg {
    top: -100%;
}

.billboardItem_9 .billboardTile_3 .billboardImg {
    top: -200%;
}

.billboardItem_9_on .billboardTile_1 {
    animation-name: billboardItem_9_on_1;
}

.billboardItem_9_on_reverse .billboardTile_1 {
    animation-name: billboardItem_9_on_reverse_1;
}

.billboardItem_9_on .billboardTile_2 {
    animation-name: billboardItem_9_on_2;
}

.billboardItem_9_on_reverse .billboardTile_2 {
    animation-name: billboardItem_9_on_reverse_2;
}

.billboardItem_9_on .billboardTile_3 {
    animation-name: billboardItem_9_on_3;
}

.billboardItem_9_on_reverse .billboardTile_3 {
    animation-name: billboardItem_9_on_reverse_3;
}

.billboardItem_9_off .billboardTile_1 {
    animation-name: billboardItem_9_off_1;
}

.billboardItem_9_off_reverse .billboardTile_1 {
    animation-name: billboardItem_9_off_reverse_1;
}

.billboardItem_9_off .billboardTile_2 {
    animation-name: billboardItem_9_off_2;
}

.billboardItem_9_off_reverse .billboardTile_2 {
    animation-name: billboardItem_9_off_reverse_2;
}

.billboardItem_9_off .billboardTile_3 {
    animation-name: billboardItem_9_off_3;
}

.billboardItem_9_off_reverse .billboardTile_3 {
    animation-name: billboardItem_9_off_reverse_3;
}

@keyframes billboardItem_9_on_1 {
    0% {
        transform: rotateY(-90deg);
    }

    60% {
        transform: rotateY(0deg);
    }

    100% {
        transform: rotateY(0deg);
    }
}

@keyframes billboardItem_9_on_reverse_1 {
    0% {
        transform: rotateY(90deg);
    }

    60% {
        transform: rotateY(0deg);
    }

    100% {
        transform: rotateY(0deg);
    }
}

@keyframes billboardItem_9_on_2 {
    0% {
        transform: rotateY(-90deg);
    }

    20% {
        transform: rotateY(-90deg);
    }

    80% {
        transform: rotateY(0deg);
    }

    100% {
        transform: rotateY(0deg);
    }
}

@keyframes billboardItem_9_on_reverse_2 {
    0% {
        transform: rotateY(90deg);
    }

    20% {
        transform: rotateY(90deg);
    }

    80% {
        transform: rotateY(0deg);
    }

    100% {
        transform: rotateY(0deg);
    }
}

@keyframes billboardItem_9_on_3 {
    0% {
        transform: rotateY(-90deg);
    }

    40% {
        transform: rotateY(-90deg);
    }

    100% {
        transform: rotateY(0deg);
    }
}

@keyframes billboardItem_9_on_reverse_3 {
    0% {
        transform: rotateY(90deg);
    }

    40% {
        transform: rotateY(90deg);
    }

    100% {
        transform: rotateY(0deg);
    }
}

@keyframes billboardItem_9_off_1 {
    0% {
        transform: rotateY(0deg);
    }

    60% {
        transform: rotateY(90deg);
    }

    100% {
        transform: rotateY(90deg);
    }
}

@keyframes billboardItem_9_off_reverse_1 {
    0% {
        transform: rotateY(0deg);
    }

    60% {
        transform: rotateY(-90deg);
    }

    100% {
        transform: rotateY(-90deg);
    }
}

@keyframes billboardItem_9_off_2 {
    0% {
        transform: rotateY(0deg);
    }

    20% {
        transform: rotateY(0deg);
    }

    80% {
        transform: rotateY(90deg);
    }

    100% {
        transform: rotateY(90deg);
    }
}

@keyframes billboardItem_9_off_reverse_2 {
    0% {
        transform: rotateY(0deg);
    }

    20% {
        transform: rotateY(0deg);
    }

    80% {
        transform: rotateY(-90deg);
    }

    100% {
        transform: rotateY(-90deg);
    }
}

@keyframes billboardItem_9_off_3 {
    0% {
        transform: rotateY(0deg);
    }

    40% {
        transform: rotateY(0deg);
    }

    100% {
        transform: rotateY(90deg);
    }
}

@keyframes billboardItem_9_off_reverse_3 {
    0% {
        transform: rotateY(0deg);
    }

    40% {
        transform: rotateY(0deg);
    }

    100% {
        transform: rotateY(-90deg);
    }
}

/* 10 垂直魔方旋转*/
.billboard10 {
    -webkit-perspective: none;
    /*safari 内核会让背景计算错误，拉伸了*/
}

.billboard10 * {
    transform-style: preserve-3d;
}

.billboardItem_10 {
    width: 100%;
    height: 100%;
}

.billboardItem_10 * {
    backface-visibility: hidden;
}

.billboardItem_10 .billboardImgInner {
    width: 100% !important;
}

.billboardItem_10 .billboardTile {
    position: absolute;
    width: 33.43%;
    height: 100%;
    top: 0;
    overflow: hidden;
}

.billboardItem_10_start .billboardTile,
.billboardItem_10_off .billboardTile,
.billboardItem_10_on .billboardTile {
    visibility: visible;
}

.billboardItem_10 .billboardTile_1 {
    left: 0;
}

.billboardItem_10 .billboardTile_2 {
    left: 33.33%;
}

.billboardItem_10 .billboardTile_3 {
    left: 66.65%;
}

.billboardItem_10 .billboardImg {
    position: absolute;
    left: 0;
    width: 300%;
    height: 100%;
}

.billboardItem_10 .billboardTile_1 .billboardImg {
    left: 0;
}

.billboardItem_10 .billboardTile_2 .billboardImg {
    left: -100%;
}

.billboardItem_10 .billboardTile_3 .billboardImg {
    left: -200%;
}

.billboardItem_10_on .billboardTile_1 {
    animation-name: billboardItem_10_on_1;
}

.billboardItem_10_on_reverse .billboardTile_1 {
    animation-name: billboardItem_10_on_reverse_1;
}

.billboardItem_10_on .billboardTile_2 {
    animation-name: billboardItem_10_on_2;
}

.billboardItem_10_on_reverse .billboardTile_2 {
    animation-name: billboardItem_10_on_reverse_2;
}

.billboardItem_10_on .billboardTile_3 {
    animation-name: billboardItem_10_on_3;
}

.billboardItem_10_on_reverse .billboardTile_3 {
    animation-name: billboardItem_10_on_reverse_3;
}

.billboardItem_10_off .billboardTile_1 {
    animation-name: billboardItem_10_off_1;
}

.billboardItem_10_off_reverse .billboardTile_1 {
    animation-name: billboardItem_10_off_reverse_1;
}

.billboardItem_10_off .billboardTile_2 {
    animation-name: billboardItem_10_off_2;
}

.billboardItem_10_off_reverse .billboardTile_2 {
    animation-name: billboardItem_10_off_reverse_2;
}

.billboardItem_10_off .billboardTile_3 {
    animation-name: billboardItem_10_off_3;
}

.billboardItem_10_off_reverse .billboardTile_3 {
    animation-name: billboardItem_10_off_reverse_3;
}

@keyframes billboardItem_10_on_1 {
    0% {
        transform: rotateX(90deg);
    }

    60% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(0deg);
    }
}

@keyframes billboardItem_10_on_reverse_1 {
    0% {
        transform: rotateX(-90deg);
    }

    60% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(0deg);
    }
}

@keyframes billboardItem_10_on_2 {
    0% {
        transform: rotateX(90deg);
    }

    20% {
        transform: rotateX(90deg);
    }

    90% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(0deg);
    }
}

@keyframes billboardItem_10_on_reverse_2 {
    0% {
        transform: rotateX(-90deg);
    }

    20% {
        transform: rotateX(-90deg);
    }

    90% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(0deg);
    }
}

@keyframes billboardItem_10_on_3 {
    0% {
        transform: rotateX(90deg);
    }

    40% {
        transform: rotateX(90deg);
    }

    100% {
        transform: rotateX(0deg);
    }
}

@keyframes billboardItem_10_on_reverse_3 {
    0% {
        transform: rotateX(-90deg);
    }

    40% {
        transform: rotateX(-90deg);
    }

    100% {
        transform: rotateX(0deg);
    }
}

@keyframes billboardItem_10_off_1 {
    0% {
        transform: rotateX(0deg);
    }

    60% {
        transform: rotateX(-90deg);
    }

    100% {
        transform: rotateX(-90deg);
    }
}

@keyframes billboardItem_10_off_reverse_1 {
    0% {
        transform: rotateX(0deg);
    }

    60% {
        transform: rotateX(90deg);
    }

    100% {
        transform: rotateX(90deg);
    }
}

@keyframes billboardItem_10_off_2 {
    0% {
        transform: rotateX(0deg);
    }

    20% {
        transform: rotateX(0deg);
    }

    90% {
        transform: rotateX(-90deg);
    }

    100% {
        transform: rotateX(-90deg);
    }
}

@keyframes billboardItem_10_off_reverse_2 {
    0% {
        transform: rotateX(0deg);
    }

    20% {
        transform: rotateX(0deg);
    }

    90% {
        transform: rotateX(90deg);
    }

    100% {
        transform: rotateX(90deg);
    }
}

@keyframes billboardItem_10_off_3 {
    0% {
        transform: rotateX(0deg);
    }

    40% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(-90deg);
    }
}

@keyframes billboardItem_10_off_reverse_3 {
    0% {
        transform: rotateX(0deg);
    }

    40% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(90deg);
    }
}

/* 11 顺风车旋转 */
.billboardItem_11 {
    /*visibility: hidden;*/
}

.billboardItem_11 .billboardImgInner {
    width: 100% !important;
}

.billboardItem_11 .billboardTile {
    width: 50%;
    height: 50%;
    /*安卓机中间有条水平白线*/
    position: absolute;
    overflow: hidden;
}

.billboardItem_11_start {
    /*visibility: visible;*/
}

.billboardItem_11_on {
    /*visibility: visible;
	z-index: 1;*/
}

.billboardItem_11_off {
    /*visibility: visible;
	z-index: 2;*/
}

.billboardItem_11 .billboardTile_1 {
    top: 0;
    left: 0;
}

.billboardItem_11 .billboardTile_2 {
    top: 0;
    right: 0;
}

.billboardItem_11 .billboardTile_3 {
    bottom: 0;
    left: 0;
}

.billboardItem_11 .billboardTile_4 {
    bottom: 0;
    right: 0;
}

.billboardItem_11 .billboardTileImg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.billboardItem_11 .billboardTile_1 .billboardTileImg {
    transform-origin: 100% 100%;
}

.billboardItem_11 .billboardTile_2 .billboardTileImg {
    transform-origin: 0 100%;
}

.billboardItem_11 .billboardTile_3 .billboardTileImg {
    transform-origin: 100% 0;
}

.billboardItem_11 .billboardTile_4 .billboardTileImg {
    transform-origin: 0 0;
}

.billboardItem_11 .billboardImg {
    width: 200%;
    height: 200%;
    position: absolute;
}

.billboardItem_11 .billboardTile_1 .billboardImg {
    top: 0;
    left: 0;
}

.billboardItem_11 .billboardTile_2 .billboardImg {
    top: 0;
    right: 0;
}

.billboardItem_11 .billboardTile_3 .billboardImg {
    bottom: 0;
    left: 0;
}

.billboardItem_11 .billboardTile_4 .billboardImg {
    bottom: 0;
    right: 0;
}

.billboardItem_11_on .billboardTileImg {
    animation-name: billboardItem_11_on;
}

.billboardItem_11_off .billboardTile_1 .billboardTileImg {
    animation-name: billboard_11_off_1;
}

.billboardItem_11_off .billboardTile_2 .billboardTileImg {
    animation-name: billboard_11_off_2;
}

.billboardItem_11_off .billboardTile_3 .billboardTileImg {
    animation-name: billboard_11_off_3;
}

.billboardItem_11_off .billboardTile_4 .billboardTileImg {
    animation-name: billboard_11_off_4;
}

@keyframes billboardItem_11_on {
    0% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes billboard_11_off_1 {
    0% {
        left: 0;
    }

    100% {
        left: 100%;
    }
}

@keyframes billboard_11_off_2 {
    0% {
        top: 0;
    }

    100% {
        top: 100%;
    }
}

@keyframes billboard_11_off_3 {
    0% {
        top: 0;
    }

    100% {
        top: -100%;
    }
}

@keyframes billboard_11_off_4 {
    0% {
        left: 0;
    }

    100% {
        left: -100%;
    }
}

/* 12 顺风车旋转 */
.billboardItem_12 {
    /*visibility: hidden;*/
}

.billboardItem_12 .billboardImgInner {
    width: 100% !important;
}

.billboardItem_12 .billboardTile {
    width: 50%;
    height: 50%;
    /*安卓机中间有条水平白线*/
    position: absolute;
    overflow: hidden;
}

.billboardItem_12_start {
    /*visibility: visible;*/
}

.billboardItem_12_on {
    /*visibility: visible;
	z-index: 1;*/
}

.billboardItem_12_off {
    /*visibility: visible;
	z-index: 2;*/
}

.billboardItem_12 .billboardTile_1 {
    top: 0;
    left: 0;
}

.billboardItem_12 .billboardTile_2 {
    top: 0;
    right: 0;
}

.billboardItem_12 .billboardTile_3 {
    bottom: 0;
    left: 0;
}

.billboardItem_12 .billboardTile_4 {
    bottom: 0;
    right: 0;
}

.billboardItem_12 .billboardTileImg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.billboardItem_12 .billboardTile_1 .billboardTileImg {
    transform-origin: 100% 100%;
}

.billboardItem_12 .billboardTile_2 .billboardTileImg {
    transform-origin: 0 100%;
}

.billboardItem_12 .billboardTile_3 .billboardTileImg {
    transform-origin: 100% 0;
}

.billboardItem_12 .billboardTile_4 .billboardTileImg {
    transform-origin: 0 0;
}

.billboardItem_12 .billboardImg {
    width: 200%;
    height: 200%;
    position: absolute;
}

.billboardItem_12 .billboardTile_1 .billboardImg {
    top: 0;
    left: 0;
}

.billboardItem_12 .billboardTile_2 .billboardImg {
    top: 0;
    right: 0;
}

.billboardItem_12 .billboardTile_3 .billboardImg {
    bottom: 0;
    left: 0;
}

.billboardItem_12 .billboardTile_4 .billboardImg {
    bottom: 0;
    right: 0;
}

.billboardItem_12_on .billboardTileImg {
    animation-name: billboardItem_12_on;
}

.billboardItem_12_off .billboardTile_1 .billboardTileImg {
    animation-name: billboard_12_off_1;
}

.billboardItem_12_off .billboardTile_2 .billboardTileImg {
    animation-name: billboard_12_off_2;
}

.billboardItem_12_off .billboardTile_3 .billboardTileImg {
    animation-name: billboard_12_off_3;
}

.billboardItem_12_off .billboardTile_4 .billboardTileImg {
    animation-name: billboard_12_off_4;
}

@keyframes billboardItem_12_on {
    0% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes billboard_12_off_1 {
    0% {
        top: 0;
    }

    100% {
        top: 100%;
    }
}

@keyframes billboard_12_off_2 {
    0% {
        left: 0;
    }

    100% {
        left: -100%;
    }
}

@keyframes billboard_12_off_3 {
    0% {
        left: 0;
    }

    100% {
        left: 100%;
    }
}

@keyframes billboard_12_off_4 {
    0% {
        top: 0;
    }

    100% {
        top: -100%;
    }
}

/* 13 分区聚焦 */
.billboardItem_13 .billboardImgInner {
    width: 100% !important;
}

.billboardItem_13 .billboardTile {
    width: 25.1%;
    height: 100%;
    position: absolute;
    top: 0;
    overflow: hidden;
}

.billboardItem_13 .billboardTile_1 {
    left: 0;
}

.billboardItem_13 .billboardTile_2 {
    left: 25%;
}

.billboardItem_13 .billboardTile_3 {
    left: 50%;
}

.billboardItem_13 .billboardTile_4 {
    left: 75%;
}

.billboardItem_13 .billboardTileImg {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    opacity: 0;
}

.billboardItem_13 .billboardImg {
    height: 100%;
    width: 400%;
    position: absolute;
    top: 0;
}

.billboardItem_13 .billboardTile_1 .billboardImg {
    left: 0;
}

.billboardItem_13 .billboardTile_2 .billboardImg {
    left: -100%;
}

.billboardItem_13 .billboardTile_3 .billboardImg {
    left: -200%;
}

.billboardItem_13 .billboardTile_4 .billboardImg {
    left: -300%;
}

.billboardItem_13_start .billboardTileImg {
    opacity: 1;
}

.billboardItem_13_off .billboardTileImg {
    animation-name: billboardItem_13_off;
}

.billboardItem_13_on .billboardTileImg {
    animation-name: billboardItem_13_on;
}

@keyframes billboardItem_13_on {
    0% {
        transform: scale(1.8);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes billboardItem_13_off {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(1.8);
        opacity: 0;
    }
}
