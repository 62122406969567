.newsDetail_hide--img {
    max-width: 15.15rem;
    height: 8.34rem;
    width: 100%;
    margin: 4.56rem auto 1.55rem;
    background: url(/image/v2/lookTipImg.png);
    background-size: contain;
    background-repeat: no-repeat;
}
.newsDetail_hide--text {
    text-align: center;
    font-size: 0.54rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-bottom: 50px;
}
.newsNewDetailPanel .msgArea {
.msgBottom {
.time {
            font-size: 0.512rem;
            color: #b3b3b3;
}
.msgDel {
            font-size: 0.512rem;
            margin-left: 0.4267rem;
            color: #999;
}
}
}
